import React from 'react';
import {
  REQUEST_CONTENT_PENDING,
  REQUEST_CONTENT_SUCCESS,
  REQUEST_CONTENT_FAIL,
  REQUEST_CONTENT_DETAIL_PENDING,
  REQUEST_CONTENT_DETAIL_SUCCESS,
  REQUEST_CONTENT_DETAIL_FAIL,
  SAVE_CONTENT_SUCCESS,
  SAVE_CONTENT_FAILED,
  CLEAR_CONTENT,
  CLEAR_CONTENT_DETAIL

} from '../actions/content';

export const contentReducer = (state = {loading: false, data: null, clearCache: true}, action) => {
  switch(action.type) {
    case REQUEST_CONTENT_PENDING:
      return {...state, loading: true};
    case REQUEST_CONTENT_SUCCESS:
      return {...state, loading: false, data: action.data, clearCache: false};
    case REQUEST_CONTENT_FAIL:
      return {...state, loading: false};
    case CLEAR_CONTENT:
      return {...state, clearCache: true, data: null};
    default:
      return state;
  }
};

export const contentDetailReducer = (state = {loading: false, data: null, beneficiaries: null}, action) => {
  switch(action.type) {
    case REQUEST_CONTENT_DETAIL_PENDING:
      return {...state, loading: true};
    case REQUEST_CONTENT_DETAIL_SUCCESS:
      return {...state, loading: false, data: action.payload, beneficiaries: action.beneficiaries};
    case REQUEST_CONTENT_DETAIL_FAIL:
      return {...state, loading: false};
    case SAVE_CONTENT_SUCCESS:
      return {...state, loading: false, data: action.payload};
    case SAVE_CONTENT_FAILED:
      return {...state, loading: false};
    case CLEAR_CONTENT_DETAIL:
      return {...state, loading: false, data: null};
    default:
      return state;
  }
};

export const getClearCache = state => {
  return state.content.clearCache;
};

export const getContentDetail = state => {
  return state.contentDetail.data ? {
    id: state.contentDetail.data.id,
    full_id: state.contentDetail.data.full_id,
    images: state.contentDetail.data.images,
    artist: state.contentDetail.data.artist,
    title: state.contentDetail.data.title,
    medium: state.contentDetail.data.medium,
    year: state.contentDetail.data.year,
    size: state.contentDetail.data.size,
    edition: state.contentDetail.data.edition,
    gallery: state.contentDetail.data.gallery,
    house: state.contentDetail.data.house,
    room: state.contentDetail.data.room,
    tags: state.contentDetail.data.tags,
    links: state.contentDetail.data.links,
    documents: state.contentDetail.data.documents,
    currentValue: state.contentDetail.data.current_value,
    marketValue: state.contentDetail.data.market_value,
    purchasePrice: state.contentDetail.data.purchase_price,
    frameValue: state.contentDetail.data.frame_value,
    framePrice: state.contentDetail.data.frame_price,
    frameCost: state.contentDetail.data.frame_cost,
    purchaseDate: state.contentDetail.data.purchase_date,
    retailPrice: state.contentDetail.data.retail_price,
    provenance: state.contentDetail.data.provenance,
    beneficiary: state.contentDetail.data.beneficiary,
    condition: state.contentDetail.data.art_condition,
    collectorsNote: state.contentDetail.data.collectors_note,
    framingDetails: state.contentDetail.data.framing_details,
    additionalDetails: state.contentDetail.data.additional_details,
    installDate: state.contentDetail.data.install_date,
  } : null;
};

export const getBeneficiaries = state => {
  return state.contentDetail.beneficiaries
};

export const getLoading = state => {
  return state.content.loading;
};

export const getCollection = state => {
  return state.content.data ? state.content.data.collection : null;
};