import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {requestContentDetail} from "../../actions/content";
import {saveContent} from '../../actions/content';
import {getContentDetail, getBeneficiaries} from '../../reducers/index';
import settings from '../../../settings';
import ContentSummary from '../ContentSummary/ContentSummary';
import FontAwesome from 'react-fontawesome';
import Modal from '../Modal/Modal';
import Select from 'react-select';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-select/dist/react-select.css';
import {getScrollTop} from "../../../utils/windowUtils";
import DocumentList from '../DocumentList/DocumentList';
import './ContentDetail.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ConditionDocumentList from "../DocumentList/ConditionDocumentList";
import {getAuthToken} from "../../reducers";
import {clearContentDetail} from "../../actions/content";
import {bindActionCreators} from 'redux';
import {getSelected} from "../../reducers/contentSelection";
import {getRole} from "../../reducers/auth";

class ContentDetailComponent extends Component {

  static propTypes = {
    content: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveEditor = this.saveEditor.bind(this);
    this.updateScroll = this.updateScroll.bind(this);
    this.saving = false;
    this.editorUpdated = false;
    this.summaryHeight = null;
    this.state = {
      showModal: false,
      showIntendedFor: false,
      initialModalIndex: 0,
      showCollectorsNote: false,
      editorState: EditorState.createEmpty(),
      shrink: false
    };
  }

  handleImageClick(index) {
    this.setState({showModal: true, initialModalIndex: index})
  }

  handleModalClose(image) {
    this.setState({showModal: false})
  }

  handleSelect(event) {
    const value = event ? event.value : null;
    this.setState({showIntendedFor: false});
    this.props.saveContent({beneficiary_id: value}, {id: this.props.match.params.id}, this.props.token);
  }

  handleInputChange(editorState) {
    this.setState({editorState});
  }

  saveEditor() {
    if (!this.saving) {
      const contentState = this.state.editorState.getCurrentContent();
      const html = draftToHtml(convertToRaw(contentState));
      const data = {
        collectors_note: html,
      };
      this.setState({showCollectorsNote: false});
      this.props.saveContent(data, {id: this.props.match.params.id}, this.props.token);
    }
  }

  cancelEditor() {
    this.setState({showCollectorsNote: false});
  }

  updateScroll(e) {

    // prevent shrink on mobile devices
    if (window.innerWidth < 1200) {
      return null;
    }

    if (!this.summaryHeight) {
      const summary = document.querySelector('.summary');
      this.summaryHeight = 570;
    }

    if (getScrollTop() >= this.summaryHeight - 200) {

      if (!this.state.shrink) {
        this.setState({shrink: true});

        setTimeout(() => {
          this.setState({fixed: true});
        }, 10);
      }

    }
    else if(this.state.shrink) {
      this.setState({shrink: false, fixed: false});
    }
  }

  componentWillMount() {
    if (!this.props.loading) {
      this.id = this.props.match.params.id;
      this.props.clearContentDetail();
      this.props.requestContentDetail({...this.props.selected, id: this.id}, this.props.token, settings.internal.contentEndpoint + '/' + this.id);
    }

    document.addEventListener('scroll', this.updateScroll, false)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.updateScroll, false);
  }

  componentWillUpdate(prevProps) {
    if (!this.props.loading && this.props.match.params.id != this.id) {
      this.id = this.props.match.params.id;
      this.props.clearContentDetail();
      this.props.requestContentDetail({...this.props.selected, id: this.id}, this.props.token, settings.internal.contentEndpoint + '/' + this.id);
    }
  }

  render() {
    if (!this.props.content) {
      return (<div className="loading"><FontAwesome name="spinner" className="fa-spin" /></div>);
    }

    const {
      loading,
      beneficiaries,
      content: {
        title,
        links,
        documents,
        currentValue,
        marketValue,
        purchasePrice,
        frameValue,
        frameCost,
        framePrice,
        appraisal,
        purchaseDate,
        retailPrice,
        provenance,
        condition,
        collectorsNote,
        framingDetails,
        additionalDetails,
        installDate,
        beneficiary
      }
    } = this.props;

    let selectedBeneficiary;

    if (beneficiary) {
      selectedBeneficiary = {value: beneficiary.id, label: beneficiary.name};
    }

    let intendedForOptions;

    if (beneficiaries) {
      intendedForOptions = Object.keys(beneficiaries).map(key => {
        return {value: beneficiaries[key].id, label: beneficiaries[key].short_name}
      });
    }

    let linksMarkup = [];

    if (links) {
      linksMarkup = Object.keys(links).map((key, index) => {
        return (
          <li key={index}>
            <a href={links[key].url} target="_blank">{links[key].title}</a>
          </li>
        );
      });
    }

    // documents
    let invoices = [];
    let appraisals = [];
    let conditionReport = [];
    let galleryInfo = [];
    let literature = [];
    let designersheet = [];
    let resume = [];
    let coo = [];
    let coa = [];
    let artistStatement = [];
    let memo = null;
    let artInformation = [];
    let packingList = [];
    let other = [];

    if (documents) {
      if (documents['Invoice']) {
        invoices = documents['Invoice'];
      }

      if (documents['Appraisal']) {
        appraisals = documents['Appraisal'];
      }

      if (documents['Condition Report']) {
        conditionReport = documents['Condition Report'];
      }

      if (documents['Literature']) {
        literature = documents['Literature'];
      }

      if (documents['Gallery Info']) {
        galleryInfo = documents['Gallery Info'];
      }

      if (documents['Resume']) {
        resume = documents['Resume'];
      }

      if (documents['Artist Statement']) {
        artistStatement = documents['Artist Statement'];
      }

      if (documents['Memo']) {
        memo = documents['Memo'];
      }

      if (documents['Art-Information']) {
        artInformation = documents['Art-Information'];
      }

      if (documents['Packing List']) {
        packingList = documents['Packing List'];
      }

      if (documents['Designer Sheet']) {
        designersheet = documents['Designer Sheet'];
      }

      if (documents['COO']) {
        coo = documents['COO'];
      }

      if (documents['COA']) {
        coa = documents['COA'];
      }
    }

    const shrinkClasses = (this.state.shrink ? 'shrink ' : '') +  (this.state.fixed ? 'fixed' : '');
    const isDesignerRole = (this.props.role.value) === "0" ? true : false;

    return (
      <React.Fragment>
        <div className="content container-fluid content-detail wrap">

          { loading &&
          <div className="loading"><FontAwesome name="spinner" /></div>
          }

          <div className="row">
            <div className="col-12">

              <Link className="btn back-btn" to={'/' + settings.contentSlug}><FontAwesome name="angle-left" /> Back to {settings.contentSlug}</Link>

              <div className={shrinkClasses} style={{height: this.summaryHeight || 'auto'}}>
                <ContentSummary detailVisible={true} item={this.props.content} onImageClick={this.handleImageClick} />
              </div>

              <div className="detail container-fluid">
                <div className="row">
                  <div className="col-sm-6">

                    { (currentValue || marketValue || purchasePrice || frameValue || purchaseDate) &&
                    <div>
                      <h4>Current Value | <small>{currentValue || 'unkown'}</small></h4>
                      {marketValue && <div>Art | {marketValue}</div>}
                      {frameValue && <div>Frame | {frameValue}</div>}
                      {purchaseDate && <div>Purchase Date | {purchaseDate}</div>}
                    </div>
                    }

                    {(retailPrice || purchasePrice || framePrice || frameCost || appraisal) &&
                    <div>
                      <h4>Value History</h4>
                      {retailPrice && <div>Retail Price, {retailPrice}</div>}
                      {purchasePrice && <div>Purchase Price, {purchasePrice}</div>}
                      {framePrice && <div>Frame Price, {framePrice}</div>}
                      {frameCost && <div>Frame Cost, {frameCost}</div>}
                      {appraisal && <div>Appraisal, {appraisal}</div>}
                    </div>
                    }

                    { ((invoices && invoices.length > 0)
                      || (appraisals && appraisals.length > 0)
                      || (designersheet && designersheet.length > 0)
                      || (coo && coo.length > 0)
                      || (coa && coa.length > 0)) &&
                    <div className="value-documents docs">
                      <h4>Value Documents</h4>
                      <DocumentList docs={invoices}/>
                      <DocumentList docs={appraisals}/>
                      <DocumentList docs={designersheet}/>
                      <DocumentList docs={coo}/>
                      <DocumentList docs={coa}/>
                    </div>
                    }

                    { (!isDesignerRole) &&
                    <div className="provenance">
                      <h4>Provenance</h4>
                      {provenance && <div className="provenance" dangerouslySetInnerHTML={{__html: provenance}}></div>}

                      {(literature && literature.length > 0) &&
                      <div className="literature-documents docs">
                        <h4>Literature</h4>
                        <DocumentList docs={literature}/>
                      </div>
                      }

                      {!isDesignerRole &&
                      <div className="intended-for">
                        <h4>
                          Intended For
                          {(!this.state.showIntendedFor && beneficiary) &&
                          <span onClick={() => this.setState({showIntendedFor: true})}> {beneficiary.name}</span>
                          }
                        </h4>
                        {!this.state.showIntendedFor &&
                        <FontAwesome className="open-select" onClick={() => this.setState({showIntendedFor: true})}
                                     name="caret-down"/>
                        }
                        {this.state.showIntendedFor &&
                        <Select
                          name="intended"
                          className="selector"
                          placeholder="Select a person"
                          autoFocus={true}
                          openOnFocus={true}
                          value={selectedBeneficiary}
                          onChange={(e) => this.handleSelect(e, 'intended')}
                          onBlur={() => this.setState({showIntendedFor: false})}
                          options={intendedForOptions}
                        />
                        }
                      </div>
                      }
                    </div>
                    }

                    { (condition || (conditionReport && conditionReport.length > 0)) &&
                    <div className="condition">
                      <h4>Condition</h4>
                      {condition && <div className="condition-report" dangerouslySetInnerHTML={{__html: condition}}></div>}

                      {(conditionReport && conditionReport.length > 0) &&
                      <div className="condition-documents docs">
                        <ConditionDocumentList docs={conditionReport} title={title} />
                      </div>
                      }
                    </div>
                    }

                    { framingDetails &&
                    <div className="framing">
                      <h4>Framing</h4>
                      {framingDetails && <div className="framing-details" dangerouslySetInnerHTML={{__html: framingDetails}}></div>}
                    </div>
                    }

                  </div>
                  <div className="col-sm-6">
                    {false &&
                      <React.Fragment>
                        <div className="glossary-links">
                          <h4>Glossary</h4>
                          <a className="btn btn-link brand-link open-collectors-note"
                             onClick={() => {}}>Edit</a>
                        </div>

                        <div className="maps-links">
                          <h4>Maps</h4>
                          <a className="btn btn-link brand-link open-collectors-note"
                             onClick={() => {}}>Edit</a>
                        </div>

                        <div className="events-links">
                          <h4>Events</h4>
                          <a className="btn btn-link brand-link open-collectors-note"
                             onClick={() => {}}>Edit</a>
                        </div>
                      </React.Fragment>
                    }

                    { installDate &&
                    <div className="install-date">
                      <h4>Installation Date | <small>{installDate}</small></h4>
                    </div>
                    }

                    {((galleryInfo && galleryInfo.length > 0)
                      || (resume && resume.length > 0)
                      || (links && links.length > 0)
                      || (artistStatement && artistStatement.length > 0)
                      || (memo && memo.length > 0)
                      || (artInformation && artInformation.length > 0)
                      || (packingList && packingList.length > 0)) &&
                    <div className="artist-documents docs">
                      <h4>Artist Documents & Links</h4>
                      {(galleryInfo && galleryInfo.length > 0) && <DocumentList docs={galleryInfo}/> }
                      {(resume && resume.length > 0) && <DocumentList docs={resume}/> }
                      {(artistStatement && artistStatement.length > 0) && <DocumentList docs={artistStatement}/> }
                      {(memo && memo.length > 0) && <DocumentList docs={memo}/> }
                      {(artInformation && artInformation.length > 0) && <DocumentList docs={artInformation}/> }
                      {(packingList && packingList.length > 0) && <DocumentList docs={packingList}/> }

                      { (linksMarkup && linksMarkup.length > 0) &&
                      <div className="links">
                        <ul>{linksMarkup}</ul>
                      </div>
                      }
                    </div>
                    }

                    { additionalDetails &&
                    <div className="additional-details">
                      <h4>Additional Details</h4>
                      {additionalDetails && <div className="additional-details" dangerouslySetInnerHTML={{__html: additionalDetails}}></div>}
                    </div>
                    }

                    {!isDesignerRole &&
                    <div className="collectors-note">
                      <h4>Collector's Note</h4>
                      {!this.state.showCollectorsNote &&
                      <a className="btn btn-link brand-link open-collectors-note"
                         onClick={() => this.setState({showCollectorsNote: true})}>Edit</a>
                      }

                      {(collectorsNote && !this.state.showCollectorsNote) &&
                      <p className="editors-note" dangerouslySetInnerHTML={{__html: this.props.collectorsNote}} />
                      }

                      {this.state.showCollectorsNote &&
                      <React.Fragment>
                        <Editor
                          defaultEditorState={this.props.intitialEditorContent}
                          onEditorStateChange={this.handleInputChange}
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
                            inline: {
                              options: ['bold', 'italic', 'underline', 'strikethrough'],
                            }
                          }}
                        />
                        <button className="btn btn-primary btn-brand" onClick={this.saveEditor}>Save Changes</button>
                        <button className="btn btn-caution brand-link" onClick={() => this.setState({showCollectorsNote: false})}>Cancel</button>
                      </React.Fragment>
                      }
                    </div>
                    }

                    {false &&
                    <div className="schedule-links">
                      <h4>Scheduled Items</h4>
                      <a className="btn btn-link brand-link open-collectors-note"
                         onClick={() => {}}>New Task</a>
                    </div>
                    }

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>

        {this.state.showModal &&
          <Modal item={this.props.content} initialIndex={this.state.initialModalIndex} onModalClose={this.handleModalClose} />
        }
      </React.Fragment>
    );
  }

};

const mapStateToProps = state => {
  const content = getContentDetail(state);
  let editorState = EditorState.createEmpty();

  if (content && content.collectorsNote) {
    const contentBlock = htmlToDraft(content.collectorsNote);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    editorState = EditorState.createWithContent(contentState);
    //collectorsNote = state.contentDetail.data.collectorsNote.replace(/['"]+/g, '');
    //editorState = EditorState.createWithContent(convertFromHTML(collectorsNote));
  }

  return {
    token: getAuthToken(state),
    role: getRole(state),
    content: content,
    beneficiaries: getBeneficiaries(state),
    intitialEditorContent: editorState,
    collectorsNote: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    selected: getSelected(state),
  }
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    requestContentDetail,
    saveContent,
    clearContentDetail
  }, dispatch)
};

const ContentDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentDetailComponent);

export default ContentDetail;